import { lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const Home = lazy(() => import('./pages/home'));
const EmailSent = lazy(() => import('./pages/email-sent'));
const EmailConfirmed = lazy(() => import('./pages/email-confirmed'));
const AlreadySubscribed = lazy(() => import('./pages/already-subscribed'));
const UnSubscribed = lazy(() => import('./pages/unsubscribed'));
const NotFound = lazy(() => import('./pages/not-found'));
const ServerError = lazy(() => import('./pages/error'));

const App = () => {
  return (
    <div className="container max-w-[640px] mx-auto flex flex-col bg-white min-h-screen font-mono">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/email-sent" element={<EmailSent />} />
          <Route path="/email-confirmed" element={<EmailConfirmed />} />
          <Route path="/already-subscribed" element={<AlreadySubscribed />} />
          <Route path="/unsubscribed" element={<UnSubscribed />} />
          <Route path="/error" element={<ServerError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
