import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App';

// process.env.NODE_ENV === "production" &&
Sentry.init({
  dsn: 'https://093a4a75b5de4c7b924528cc615645d9@o1260427.ingest.sentry.io/6436558',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
